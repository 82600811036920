<template>
    <div>
        <div
            v-for="(n, i) in number"
            :key="i"
            class="mb-3 skeleton__shimmer"
            :class="[`h-${height}`]"
        />
    </div>
</template>

<script>
export default {
    name: "PageLoader",
    props: {
        number: {
            type: Number,
            default: 4,
        },
        height: {
            type: Number,
            default: 5,
        },
    },
};
</script>

<style scoped lang="scss">
.skeleton__shimmer {
    min-width: 250px;
    background: linear-gradient(to right, #eff1f3 4%, #f8f9fb 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    animation: shimmer 3s infinite linear;
    margin-bottom: 1rem;
}
</style>
