<template>
  <div
    @dragover.prevent="boxHovered = true"
    @dragleave.prevent="boxHovered = false"
    @drop.prevent="getDroppedFile"
    class="document flex flex__column flex__item-center flex__center mb-1"
    :class="{ active: boxHovered }"
  >
    <template v-if="!fileAdded">
      <div class="mb-1">
        <img src="../../assets/icons/file-drop-icon.svg" alt="file-drop-icon" />
      </div>
      <p class="mb-1 m-fs-16">
        {{ insideText }}, or
        <label
          id="browse-file"
          name="browse-file"
          class="text-blue cursor-pointer"
          for="files"
        >
          browse
        </label>
      </p>
      <p class="fw-600 m-fs-16" style="color: #8b90a0">
        SUPPORT:
        <span class="text-uppercase">{{
          accept.join(', ').replaceAll('.', '')
        }}</span>
      </p>
    </template>
    <template v-else>
      <span class="text-blue m-fs-16">{{ fileName }}</span>
      <label
        id="browse-file"
        name="browse-file"
        class="cursor-pointer m-fs-16"
        for="files"
      >
        Browse files
      </label>
    </template>
    <input
      @change="getBrowsedFile"
      id="files"
      type="file"
      :accept="accept.join(',')"
      hidden
    />
  </div>
</template>

<script>
export default {
  name: 'FileGrabber',
  props: {
    accept: {
      type: Array,
      default: () => ['.png', '.jpg', '.doc', '.docx', '.pdf']
    },
    insideText: {
      type: String,
      default: 'Drop your Documents here'
    },
    value: {
      default: ''
    }
  },
  data() {
    return {
      fileName: '',
      fileAdded: false,
      boxHovered: false
    };
  },
  methods: {
    getDroppedFile(e) {
      this.importBoxHovered = false;
      const droppedFile = e.dataTransfer.files[0];
      this.$emit('input', droppedFile);
      this.fileAdded = true;
      this.fileName = droppedFile.name;
    },
    getBrowsedFile(e) {
      const selectedFile = e.target.files[0];
      this.$emit('input', selectedFile);
      this.fileAdded = true;
      this.fileName = selectedFile.name;
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      console.log(newValue);
      if (!newValue) {
        this.fileAdded = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use './fileGrabber.scss' as *;
</style>
